import { graphql } from "gatsby"
import FragmentProductRelated from '../commerceProductDefault/product-related'

export const query = graphql`
    fragment FragmentProductDetails on commerce_product__default {

        id
        drupal_id
        drupal_internal__product_id
        internal {
            type
        }

        langcode
        title
        body {
            summary
            value
        }
        path {
            pid
            alias
            langcode
        }
        created

        # field_link_moodle {
        #     uri
        #     title
        # }

        field_metatag {
            # basic
                title
                description
                abstract
                keywords
            # advanced
                # canonical_url
                # cache_control
        }

        relationships {
            bundle: __typename

            variations {
                ... on commerce_product_variation__default {
                    variationId: drupal_internal__variation_id
                    sku
                    title
                    price {
                        number
                        currencyCode: currency_code
                        formatted
                    }
                    resolvedPrice: resolved_price {
                        number
                        currencyCode: currency_code
                        formatted
                    }
                    calculatedPrice: calculated_price {
                        number
                        currencyCode: currency_code
                        formatted
                    }
                    relationships {
                        bundle: __typename

                        field_images {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 525) {
                                        # ...GatsbyImageSharpFluid
                                        # @see: https://www.gatsbyjs.org/packages/gatsby-image/
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }

            field_category {
                ... on taxonomy_term__kategorie_sklep {
                    name
                }
            }

            field_related_products {
                ... on commerce_product__default {
                    ...FragmentProductRelated
                }
            }

            # commerce_product_variation__default {
            # 	sku
            #     price {
            #         number
            #         currency_code
            #     }
            #     ... on commerce_product_variation__default {
            #     }
            # }

        }

    }
`