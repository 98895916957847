/**
 * @see: https://mdbootstrap.com/previews/templates/e-commerce/html/product/product-v1.html
 */
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Img from 'gatsby-image'
import Image from "./../../components/image"
import SEO from "./../../components/seo"

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
import Preloader from './../../components/s4n/Preloader'
import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import { client } from './../../apollo/client'
import graphqlQuery from "./../../model/apollo/product-details"
// import ProductReviews from "./../../components/s4n/Shop/Product/reviews"
import ProductRelated from "./../../components/s4n/Shop/Product/related"
import { Accordion, Card } from 'react-bootstrap'
// import MoodleLogo from './../../images/custom/brands/moodle_sitebar_logo_grayhat.svg'
// s4n / shop
import FragmentProductDetails from '../../model/fragments/commerceProductDefault/product-details'
import ProductImage from '../../components/s4n/Shop/Product/image'



// shop
import SimpleAddToCart from './../../components/s4n/Shop/ext/pages/Product/AddToCart/simple'
import VariationsAddToCart from './../../components/s4n/Shop/ext/pages/Product/AddToCart/variations'



const ComponentStateless = (props) => {
    const { data, intl } = props;
    const node = data.node; // === const { node } = data;
    const t = intl.formatMessage;

    const [state, setState] = useState({
        loading: false,
        error: false,
        queryResult: null,
    });

    const FormHeuresisProduktZapytanie = loadable(() => import(`./../../components/forms/webform/heuresis_produkt_zapytanie`), {fallback: <Preloader />})
    //   console.log(node)


    // prepare/extract data
    const productId = node.drupal_internal__product_id;
    const languageId = intl.locale.toUpperCase();
    const variations = node.relationships.variations ? node.relationships.variations : null;
    const defaultVariation = variations ? variations[0] : null;
    // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
    // const nodeBodyField = useParseInlineImages(node.body ? node.body.value : '', intl)
    const nodeBodyField = useParseInlineImages(node, intl)

    let aAccordionTabs = [];
    if (nodeBodyField) {
        aAccordionTabs = [
            {header: t({ id: "soft4net_shop_commerce_product_page_accordion_tabs_details" }), content: nodeBodyField},
            {header: t({ id: "soft4net_shop_commerce_product_page_accordion_tabs_inquiry" }), content: <FormHeuresisProduktZapytanie productId={productId} />},
        ];
    }

    //   console.log(defaultVariation)

    const category = node.relationships.field_category ? node.relationships.field_category.name : null;
    const img = defaultVariation && defaultVariation.relationships && defaultVariation.relationships.field_images.length ? defaultVariation.relationships.field_images[0].localFile.childImageSharp.fluid : null;

    const getCommerceProductById = async (productId, languageId) => {
        setState({
            loading: true,
        })

        // @see: https://graphql-search-api.readthedocs.io/en/latest/
        const APOLLO_QUERY = graphqlQuery(productId, languageId);
        // console.log(APOLLO_QUERY)

        let result = null;
        try {
            result = await client.query({query: APOLLO_QUERY}); // {data: {…}, loading: false, networkStatus: 7, stale: false}
            // const { data, loading, error } = await client.query({query: APOLLO_QUERY});
        } catch (error) {
            setState({
                error: error,
            })
        }

        // if (result.loading) {
        //     return <p>Loading...</p>
        // }

        // console.log(result)

        setState({
            loading: result.loading,
            queryResult: result,
        })
    }

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        getCommerceProductById(productId, languageId);
    }, []);

    const commerceProductById = !state.loading && state.queryResult ? state.queryResult.data.commerceProductById : null;

    const translation = {
        back_to_shop: {
            pl: `Wróć do sklepu`,
            en: `Back to shop`,
            de: `Zurück zum Shop`,
            es: `volver a la tienda`,
        },
    }

    let meta = [];

    if (img) {
        meta.push(
            {
                property: `image`,
                content: `${process.env.SOFT4NET_STATIC_BASE_URL}${img.src}`,
            }, 
            {
                property: `og:image`,
                content: `${process.env.SOFT4NET_STATIC_BASE_URL}${img.src}`,
            },
            {
                property: `twitter:image`,
                content: `${process.env.SOFT4NET_STATIC_BASE_URL}${img.src}`,
            }
        );
    }

    let _variations = [];
    if (commerceProductById) {
        _variations = commerceProductById.variations.filter(variation => variation.entity);
    }

    return (
        <Layout node={node}>
            <SEO 
                title={node.title} 
                keywords={`${node.title}`} 
                meta={meta} 
                field_metatag={node.field_metatag}
            />

            {/* <small><em>{ node.created }</em></small> */}

            {/* <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}>
            {img && <Img fluid={ img } />}
            </div> */}

            {/* { nodeBodyField } */}
            {/* <div dangerouslySetInnerHTML={{ __html: nodeBodyField }}></div> */}

            <div className="container">
                <h1>{ node.title }</h1>
            </div>

            <div className="container product-details">
                <section id="productDetails" className="pb-3">
                    <div className="container-fluid card hoverable">
                        <div className="row py-3">
                            <div className="col-md-4 mb-3 mb-md-0">
                                <div id="carousel-thumb">
                                    <ProductImage product={node} carousel={true} />
                                </div>
                            </div>
                            <div className="col-md-8 _mr-3 _text-center _text-md-left">
                                {/* Product variations */}
                                {defaultVariation && <section className="variants">
                                    <div className="mt-0">
                                        <div className="row mb-3">
                                            <div className="col-md-12">

                                                {/* Call backend via Apollo cient */}
                                                {state.loading ? 
                                                    <div className={`col-sm-12`} key={`loading`}>
                                                        <Preloader paddingY={3} />
                                                    </div>
                                                    :                                                
                                                    <React.Fragment>
                                                        {/* <div className={`col-md-6`}>
                                                            {image && <img src={image.derivative.url} width={image.derivative.width} height={image.derivative.height} alt={image.alt} className={`img-fluid`} />}
                                                        </div> */}

                                                        <div className={`_col-md-6`}>
                                                            <div className={`commerce-product__contents`}>
                                                                {/* <div className={`field--name-title`}>{commerceProductById.title}</div> */}
                                                                {/* <div className="field--name-field-brand">{productBrand}</div> */}
                                                                {/* <div className={`field--name-body`}>
                                                                    <div dangerouslySetInnerHTML={{__html: bodyValue}}/>
                                                                </div> */}

                                                                {/* <pre>{JSON.stringify(commerceProductById, null, 4)}</pre> */}

                                                                {_variations.length > 1 ? 
                                                                    <VariationsAddToCart variations={_variations} />
                                                                    : 
                                                                    <SimpleAddToCart defaultVariation={defaultVariation} />
                                                                }

                                                                {/* <div className={`field--name-field-product-categories`}>
                                                                    {productCategories.map(category => (
                                                                    <div key={category.entity.name}>{category.entity.name}</div>
                                                                    ))}
                                                                </div> */}

                                                                {/* <div className={`field--name-field-special-categories`}>
                                                                    {specialCategories.map(category => (
                                                                    <div key={category.entity.name}>{category.entity.name}</div>
                                                                    ))}
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </section>}

                                {/* {node.field_link_moodle && <p>
                                    <a href={node.field_link_moodle.uri} target="_blank" rel="noreferrer">
                                        { t({ id: "soft4net_shop_commerce_product_page_field_link_moodle_title" }) }
                                        <img src={MoodleLogo} alt={node.field_link_moodle.title} className="img-fluid ml-3 mb-3" width="100" />
                                    </a>
                                </p>} */}
                                <h4>{ category }</h4>

                                {aAccordionTabs.length && 
                                    <Accordion defaultActiveKey={`commerce-product-tabs-0`}>
                                        {aAccordionTabs.map((tab, index) => {
                                            return (
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey={`commerce-product-tabs-${index}`}>
                                                        <strong>{ tab.header }</strong>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={`commerce-product-tabs-${index}`}>
                                                        <Card.Body>
                                                            { tab.content }
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        })}
                                    </Accordion>
                                }

                            </div>
                        </div>
                    </div>
                </section>

                <button onClick={() => {window.history.back();}} className="btn btn-secondary back mb-3" role="button">{ translation.back_to_shop[intl.locale] }</button>

                {/* <ProductReviews /> */}

                <ProductRelated products={node.relationships && node.relationships.field_related_products ? node.relationships.field_related_products : []} />

            </div>

        </Layout>
    )
}

export default injectIntl(ComponentStateless)

// this query is Promie, we export it to let Gatsby fill variables from context from gatsby-node.js (id )
// and pass back to this file as props to below component
export const query = graphql`
    query(
        $id: String!,
        $drupal_id: String!,
        $langcode: String!
    ) {
        node: commerceProductDefault(
            id: {
                eq: $id
            },
            drupal_id: {
                eq: $drupal_id
            },
            langcode: {
                eq: $langcode
            }
        ) {
            ...FragmentProductDetails
        }
    }
`