import React from 'react'
import PropTypes from 'prop-types';
import { cartAdd } from '../../../actions'
import { connect } from 'react-redux'

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import ProductPrice from './../../../../Product/price'



const Simple = (props) => {
  const { dispatch, defaultVariation } = props;

  const { intl } = props;
  const t = intl.formatMessage;

  // console.log(defaultVariation)

  return (
    <React.Fragment>
      <h3 className="h3-responsive mb-1">
        <span className="red-text font-weight-bold">
          <ProductPrice selectedVariation={defaultVariation} printLabel={true} />
        </span>
      </h3>

      <div className={`field--name-variations`} key={defaultVariation.entityBundle}>
        <div className={`field--item add-to-cart`}>
          <button className="button button--primary js-form-submit form-submit btn-success btn" type="button" onClick={() => {
            dispatch(cartAdd(defaultVariation))
          }}>
            <i className="fas fa-cart-plus mr-2" aria-hidden="true"></i>
            {` `}
            { t({ id: "soft4net_shop_commerce_product_page_add_to_cart" }) }
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

Simple.propTypes = {
  defaultVariation: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

// export default connect()(Simple);
const SimpleWrapped = injectIntl(Simple);
export default connect()(SimpleWrapped);